html, body {
    height: 100%;
    min-height: 100%;
    margin: 0;
}

#root {
    height: 100vh;
    font-family: 'Open Sans', sans-serif;
}

h1, h2, h3 {
    font-family: 'Source Sans Pro', sans-serif;
}